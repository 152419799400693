<template>

    <!--<b-card no-body  @click="goToEvent" style="cursor: pointer">-->
    <div class="esWdgt200212-card" @click="goToEvent" style="cursor: pointer">
        <div class="esWdgt200212-card-header-img">
            <img :src="imgSrc" class="esWdgt200212-card-img esWdgt200212-card-img-top "  @error="handleImgNotFound" v-bind:class="{ 'esWdgt200212-card-img-missing': imgMissClass }" :alt="event.title" />
        </div>
        <div class="esWdgt200212-card-body">
            <div class="esWdgt200212-row">
                <div class="esWdgt200212-col-3 esWdgt200212-mb-0">
                    <div class="esWdgt200212-h4 esWdgt200212-text-secondary esWdgt200212-text-center esWdgt200212-month">{{event.shortdate[0].split("_")[1]}}</div>
                    <div class="esWdgt200212-h4 esWdgt200212-text-secondary esWdgt200212-text-center esWdgt200212-date">{{event.shortdate[0].split("_")[0]}}</div>
                </div>
                <div class="esWdgt200212-col-9 esWdgt200212-mb-0">
                    <div class="esWdgt200212-h5 esWdgt200212-evt-title" :style="event.custom_text_colour ? {color: event.custom_text_colour} : {color: defaultColour}">​{{event.title}}</div>
                    <div class="esWdgt200212-h6">{{event.datetimefrom}}</div>
                    <div class="esWdgt200212-h6" v-if="event.datetimefrom != event.datetimeto">{{event.datetimeto}}</div>
                    <div class="esWdgt200212-h6">{{event.location}}</div>
                    <div class="esWdgt200212-h6">{{event.lowest_price}}</div>
                </div>
            </div>
            <div class="esWdgt200212-socials">
                <socialMediaBtn :eventTitle="event.title" :bookingUrl="event.booking_url"></socialMediaBtn>
            </div>
        </div>
    </div>
</template>

<script>
    import socialMediaBtn from "./social-media-share";

    export default {
        data() {
            return {
                imgSrc: 'https://d1vvlogztnop7x.cloudfront.net/placeholder.png'
            }
        },
        props: ['event', 'defaultColour'],
        components: {
          socialMediaBtn: socialMediaBtn
        },
        methods: {
            goToEvent(){
                var eventUrl = ""
                if (this.event.custom_url) {
                    eventUrl = `${process.env.VUE_APP_BOOKING_URL}/${this.event.custom_url}/?referrer=org`;
                } else {
                    eventUrl = `${process.env.VUE_APP_BOOKING_URL}/event/${this.event.id}/${this.event.title.toLowerCase().replace(/[\\!&|?$%@':;.<>/"=+\s]/g, '_')}/?referrer=org`;
                }
                window.location = eventUrl
            },
            handleImgNotFound(){
                this.imgSrc = 'https://d1vvlogztnop7x.cloudfront.net/placeholder.png';
            }
        },
        mounted() {
            if(this.event.image_url) {
                this.imgSrc = `${this.event.image_url}?${Math.random()}`;
            }
        },
        watch: {
            event(newVal, oldVal) {
                this.imgSrc = newVal.image_url ? `${newVal.image_url}?${Math.random()}` : 'https://d1vvlogztnop7x.cloudfront.net/placeholder.png';
            }
        },
        computed: {
            imgMissClass(){
                return this.imgSrc === 'https://d1vvlogztnop7x.cloudfront.net/placeholder.png';
            }
        }
    }
</script>
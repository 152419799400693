<template>
    <div class="esWdgt200212-container">
        <div class="esWdgt200212-filter" :style="{background: bgdColour }">
            <div class="esWdgt200212-filters">
                <div class="esWdgt200212-filter-group">
                    <div class="esWdgt200212-form-group">
                        <label for="event_name">I'm looking for</label>
                        <div>
                            <input id="event_name" type="text" v-model="title" placeholder="Event Name" class="esWdgt200212-form-control">
                        </div>
                    </div>
                    <div class="esWdgt200212-form-group">
                        <label for="event_tag">Event Tag</label>
                        <div>
                            <input id="event_tag" type="text" v-model="tag" placeholder="Event Tag" class="esWdgt200212-form-control">
                        </div>
                    </div>
                    <div class="esWdgt200212-form-group">
                        <label for="event_location">Event Location</label>
                        <div>
                            <input id="event_location" type="text" v-model="location" placeholder="Event Location" class="esWdgt200212-form-control">
                        </div>
                    </div>
                </div>

                <div class="esWdgt200212-filter-group">
                    <div class="esWdgt200212-form-group">
                        <label for="startDate">On</label>
                        <div>
                            <datepicker v-model="dateOn" name="startDate" format="dd/MM/yyyy" placeholder="Select Event Date" class="hg-events-filter-200210" :clear-button="true" @cleared="dateCleared"></datepicker>
                        </div>
                    </div>
                    <div class="esWdgt200212-form-group">
                        <label for="event_type">Event Type</label>
                        <div>
                            <select id="event_type" class="esWdgt200212-form-control esWdgt200212-custom-select"  v-model="eventType">
                                <option :selected="true" value="All">All</option>
                                <option v-for="option in eventTypeOptions" v-bind:value="option" >{{ option }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="esWdgt200212-form-group">
                        <label for="event_by_price">By Price</label>
                        <div>
                            <select id="event_by_price" class="esWdgt200212-form-control esWdgt200212-custom-select" v-model="cost">
                                <option v-for="option in costOptions" v-bind:value="option.value" >{{ option.text }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="esWdgt200212-buttons-group">
                <button type="button" @click="filterEvents" class="esWdgt200212-btn esWdgt200212-btn-secondary" id="esWdgt200212-search-button">
                    <span><font-awesome-icon icon="search" size="5x"></font-awesome-icon></span>
                </button>
                <a @click="clearFilters">Clear filters</a>
            </div>
        </div>
    </div>
</template>
<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        data() {
            return {
                placeHolderText: "Filter text",
                title: "",
                dateOn: null,
                location: null,
                tag: null,
                eventType: 'All',
                eventTypeOptions: [],
                cost: 'all',
                costOptions: [
                    {text: 'All', value: 'all'},
                    {text: 'Free', value: 'free'},
                    {text: 'Paid', value: 'paid'}
                ]
            };
        },
        props: ['token', 'bgdColour'],
        components: {
            Datepicker
        },
        created() {
            var self = this;
            self.getEventTypeOptions();

        },
        methods: {
            filterEvents() {
                this.$emit("filterEvents", {title: this.title, date_on: this.dateOn, location: this.location, tag: this.tag, type: this.eventType, cost: this.cost});
            },
            clearFilters() {
                this.placeHolderText = "Filter text";
                this.title = "";
                this.dateOn = null;
                this.location = null,
                this.tag = null,
                this.eventType = 'All',
                this.cost = 'all'
                this.filterEvents();
            },
            getEventTypeOptions(){
                var self = this;

                this.$http
                    .get(`${process.env.VUE_APP_EVENTSTOP_API}/event_type_selector_options.json`, {headers: {'Authorization': "Bearer " + this.token}})
                    .then(function(response) {
                        self.eventTypeOptions = response.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            dateCleared(){
                this.filterEvents();
            }
        }
    };
</script>
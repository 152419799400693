import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/fontawesome'
import './plugins/bootstrap-vue';
import App from './App.vue';
import axios from "axios";
import vueCustomElement from 'vue-custom-element';
import SocialSharing from "vue-social-sharing";
import "./assets/stylesheet.scss";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Cache-Control"] =
    "no-cache,no-store,must-revalidate,max-age=-1,private";

Vue.prototype.$http = axios;

Vue.use(SocialSharing);
//Vue.use(Element, { locale })
Vue.use(vueCustomElement)

Vue.config.productionTip = false

Vue.customElement('my-events', App)

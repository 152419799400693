<template>
    <social-sharing :url="bookingUrl" :title="eventTitle" inline-template>
            <div class="text-right">
                <div class="esWdgt200212-btn esWdgt200212-round-button esWdgt200212-btn-secondary"  @click.stop>
                    <network network="email">
                        <font-awesome-icon icon="envelope"></font-awesome-icon>
                    </network>
                </div>
                
                <div class="esWdgt200212-btn esWdgt200212-round-button esWdgt200212-btn-secondary" @click.stop>
                    <network network="facebook">
                        <font-awesome-icon :icon="['fab', 'facebook-f']"></font-awesome-icon>
                        <!--<i class="fab fa-facebook-f"></i>-->
                    </network>
                </div>
                <div class="esWdgt200212-btn esWdgt200212-round-button esWdgt200212-btn-secondary" @click.stop>
                    <network network="twitter">
                        <font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon>
                        <!--<i class="fab fa-twitter"></i>-->
                    </network>
                </div>
            </div>

    </social-sharing>
</template>

<script>
    export default {
        props: ['bookingUrl', 'eventTitle'],
        name: "socialMediaBtn"
    }
</script>

<style>
    .round-button  {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
</style>
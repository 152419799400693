<template>
 <div id="splashscreen">
  <div class="esWdgt200212-h2">SEARCH FOR EVENTS</div>
  <div>
    <events-filter @filterEvents="filterEvents" :token="apiToken" :bgdColour="brand_colour"></events-filter>

    <div class="esWdgt200212-container">
      <div class="esWdgt200212-events">
        <div class="esWdgt200212-h2">UPCOMING EVENTS NEAR YOU</div>

          <event-deck v-if="pageEvents.length > 0" :events="pageEvents.length >= 6 ? pageEvents.slice(0, 6) : pageEvents.slice(0)" :brandColour="brand_colour"></event-deck>
          <event-deck v-if="pageEvents.length > 6" :events="pageEvents.slice(6)" :brandColour="brand_colour"></event-deck>
          <div class="esWdgt200212-h3" v-if="events.length == 0">No Events found.</div>

           <ul id="esWdgt200212-events-pagination" v-if="events.length > 6">
               <li class="esWdgt200212-page-item"><a class="esWdgt200212-page-link" href="#" @click.prevent="paginatePageChange('first')" :style="{color: brand_colour}"><<</a></li>
               <li class="esWdgt200212-page-item"><a class="esWdgt200212-page-link" href="#" @click.prevent="paginatePageChange('previous')" :style="{color: brand_colour}"><</a></li>
               <li v-for="page in totalPages" v-bind:class="{'esWdgt200212-page-item':true, 'active':(page === currentPage)}">
                   <a class="esWdgt200212-page-link"
                      href="#"
                      @click.prevent="paginatePageChange(page)"
                      :style="{color: page === currentPage ? '#FFFFFF' : brand_colour,
                                backgroundColor: page === currentPage ? brand_colour : '',
                                borderColor: page === currentPage ? brand_colour : ''}">
                       {{ page }}
                   </a>
               </li>

               <li class="esWdgt200212-page-item"><a class="esWdgt200212-page-link" href="#" @click.prevent="paginatePageChange('next')" :style="{color: brand_colour}">></a></li>
               <li class="esWdgt200212-page-item"><a class="esWdgt200212-page-link" href="#" @click.prevent="paginatePageChange('last')" :style="{color: brand_colour}">>></a></li>
           </ul>

      </div>
    </div>
  </div>
  </div>
</template>

<script>
import eventDeck from "./events-card-deck";
import eventsFilter from "./events-filter"

export default {
  data() {
    return {
      events: [],
      currentPage: 1,
      totalPages: 0,
      filter: {
          title: "",
          date_on: null,
          location: null,
          tag: null,
          type: null,
          cost: 'all'
      },
      events_count: 0,
      brand_colour: "",
      apiToken: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_API_TOKEN : this.accessToken
    };
  },

  props: ['accessToken'],

  created() {
    var self = this;
    self.loadData();
  },

  methods: {
    filterEvents(filter) {
      this.filter = filter;
      this.loadData();
    },

    loadData(page = 1) {
      var self = this;

      if (page) {
        self.currentPage = page;
      }

      var config = {
          headers: {'Authorization': "Bearer " + this.apiToken},
          params: {
              title: self.filter.title,
              date_on: self.filter.date_on,
              location: self.filter.location,
              tag: self.filter.tag,
              type: self.filter.type,
              cost: self.filter.cost
          }
      }

      this.$http
        .get(`${process.env.VUE_APP_EVENTSTOP_API}/public_events`, config)
        .then(function(response) {
          self.events = response.data.events;
          self.events_count = response.data.events_count;
          self.totalPages = Math.ceil(response.data.events_count / 6)
          self.brand_colour = response.data.filter_colour;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    paginatePageChange(value) {
        switch (value) {
            case 'first':
                this.currentPage = 1
                break
            case 'last':
                this.currentPage = this.totalPages
                break
            case 'next':
                this.currentPage = this.currentPage == this.totalPages ? this.totalPages : this.currentPage + 1
                break
            case 'previous':
                this.currentPage = this.currentPage == 1 ? 1 : this.currentPage - 1
                break
            default:
                this.currentPage = value
        }
    }
  },

  components: {
    eventDeck: eventDeck,
    eventsFilter: eventsFilter
  },

  computed: {
      pageEvents() {
          let start = (this.currentPage - 1) * 6;
          let end = (this.currentPage * 6);
          if(this.currentPage * 6 <= this.events.length) {
              return this.events.slice(start, end )
          } else {
              return this.events.slice(start)
          }
      }
  }
};
</script>

<template>
  <div id="hg-es-widget-app200210">
    <events-view :accessToken="token"></events-view>
  </div>
</template>

<script>
import eventsView from './components/events-view'

export default {
  name: 'app',
  props: ['token'],
  components: {
    'events-view': eventsView
  }
}
</script>
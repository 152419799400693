<template>

    <div class="esWdgt200212-card-deck">
        <event-card
                :defaultColour="brandColour"
                v-for="(event, idx) in events"
                :event="event"
                :key="idx"
                class="esWdgt200212-card"
        >
        </event-card>
    </div>

</template>

<script>
    import eventCard from "./event-card";

    export default {
        props: ['events', 'brandColour'],
        components: {
            eventCard: eventCard,
        }
    }
</script>